/* Base typography
========================================================================== */
h1, h2, h3, h4, h5, h6 {
  margin: 0;
  line-height: 1.2em;
  letter-spacing: 0.0005em;
}

h1 { font-size: 3rem;}
h2 { font-size: 2rem;}
h3 { font-size: 1.5rem; }
h4 { font-size: 2rem; }
h5 { font-size: 2.5rem; }
h6 { font-size: .875rem; }

@include breakpoint(sm) {
  h1 { font-size: calc(3.5rem + 1vw); }
  h2 { font-size: 2rem; }
  h3 { font-size: 1.5rem; }
  h4 { font-size: 2rem; }
  h5 { font-size: 2.5rem; }
  h6 { font-size: .875rem; }
}

@include breakpoint(md) {
  h1 { font-size: calc(4.5rem + 1vw); }
  h2 { font-size: 2.5rem; }
  h3 { font-size: 1.5rem; }
  h4 { font-size: 2rem; }
  h5 { font-size: 2.5rem; }
  h6 { font-size: .875rem; }
}

@include breakpoint(lg)  {
  h1 { font-size: calc(4rem + 0.35vw);}
  h2 { font-size: calc(1.8rem + 0.25vw); }
  h3 { font-size: 1.5rem; }
  h4 { font-size: 2rem; }
  h5 { font-size: 2.5rem; }
  h6 { font-size: .875rem; }
}

p{
  padding-bottom: 1rem;
}

p,
span{
  line-height: 1.25em;
}

em{
  border-bottom: 1px dotted rgb(130, 130, 130);
}

small{
  font-family: $font-sans;
}

.t-sans{
  font-family: $font-sans;
  font-size: 14px;
}

.ttu{
  letter-spacing: 0.075em;
}
/* Links
========================================================================== */
a {
  text-decoration: none;
  cursor: pointer;
}

a:hover,
a:active,
a:focus{
  // text-decoration: underline;
  // color: red;
  // padding-left: 1rem;
  // transition: 0.2s all $ease;
}


.link-black {
  display: inline-block;
  color: $black;
  text-decoration: none;
}

.link-black::after {
  content: '';
  display: block;
  width: 100%;
  height: 1px;
  background: $black;
  transition: width .3s;
  margin-top: 1px;
}

.link-black__lg {
  display: inline-block;
  color: $black;
  text-decoration: none;
}

.link-black__lg::after {
  content: '';
  display: block;
  width: 0;
  height: 1px;
  background: $black;
  transition: width .3s;
  margin-top: -5px;
}
@media (pointer: fine) {
  .link-black__lg:hover::after {
      width: 100%;
  }
}



.link-white {
    display: inline-block;
    color: $white;
    text-decoration: none;
}

.link-white::after {
    content: '';
    display: block;
    width: 0;
    height: 1px;
    background: $white;
    transition: width .3s;
}

@media (pointer: fine) {
  .link-black:hover::after,
  .link-white:hover::after {
      width: 0%;
  }
}



.link:focus{
  outline: none;
}


/* Text color
========================================================================== */
.t-brown{
  color: $brown;
}

/* Quotes
========================================================================== */
blockquote{
  font-size: calc(18px + 1vw);
  line-height: 1.3;
  margin: 0 auto;
  padding: 6.4rem 0;
  max-width: 1280px;
}

/* Lists
========================================================================== */
ul {
  list-style-type:none;
  padding: 0;
  // overflow: hidden;
}

.list-bullets{
  list-style-position: inside;
  margin-left: 0;
  padding-left: 1.5em;
  li {
    position: relative;
  }

  li::before {
    counter-increment: paragraph;
    content: "\02022";
    position: absolute;
    left: -1.5em;
  }
}

.list-decimal{
  list-style: decimal-leading-zero;
  list-style-position: inside;
}
